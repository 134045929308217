import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import blog from "../contentJSON/blog.json"
import LayoutContactMe from "../@christiandavid/gatsby-theme-byfolio/components/layout/layout-contact-me"
import styles from "../css/blog.css"

export default () => {
  return (
    <LayoutContactMe bgClassName="blog">
      <Helmet>
        <title>Blog</title>
      </Helmet>
      <section css={styles.blogSection}>
        <div css={styles.blogContainer}>
          <div css={styles.blogContent}>
            <span css={styles.blogShadow}>Blog</span>
            {blog.map(({ subtitle, content }) => (
              <Fragment key={subtitle}>
                <h2
                  css={styles.hTag}
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></h2>
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
              </Fragment>
            ))}
          </div>
        </div>
      </section>
    </LayoutContactMe>
  )
}
